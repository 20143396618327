import React from 'react';
import ReactDOM from 'react-dom';
import './index.css'; // If you have any global styles
import App from './App';
import reportWebVitals from './reportWebVitals'; // Optional if you want to track performance

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root') // Make sure this corresponds to the div in your public/index.html
);

// If you want to measure performance in your app
reportWebVitals();
