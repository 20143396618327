// src/App.js
import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Testimonials from './components/Testimonials';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Subscription from './components/Subscription';

function App() {
  return (
    <div className="App h-screen overflow-x-hidden overflow-y-auto scroll-container">
      <Header />
      <Hero />
      <AboutUs />
      <Testimonials />
      <Contact />
      <Subscription />
      <Footer />
    </div>
  );
}

export default App;
