import React from 'react';
import subscriptionBackground from '../assets/robots2.png'; // Adjust path if necessary

function Footer() {
  return (
    <footer
      className="relative text-white py-12 bg-cover bg-center h-[200px]" // Fixed height for positioning
      style={{
        backgroundImage: `url(${subscriptionBackground})`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Transparent Overlay */}
      <div className="absolute inset-0 bg-purple-800/60"></div>

      <div className="relative z-10 container mx-auto px-4 flex flex-col sm:flex-row items-center justify-between h-full text-sm">
        <p>
          Copyright © 2024 Roboti - All Rights Reserved.
        </p>
        <div className="space-x-2">
          <a href="https://roboti.app/privacy-policy/" target="_blank" rel="noopener noreferrer" className="text-white underline">Privacy Policy</a>
          <span>|</span>
          <a href="https://www.godaddy.com/" target="_blank" rel="noopener noreferrer" className="text-white underline">Powered by GoDaddy</a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
