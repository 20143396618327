// src/components/AboutUs.js
import React from 'react';
import PhoneImage1 from '../assets/phone1.png';
import PhoneImage2 from '../assets/phone2.png';
import wavesBackground from '../assets/waves_background.jpeg'; // Adjust path if necessary

const AboutUs = () => {
  return (
    <section
      id="about"  // Added id="about" for smooth scrolling
      className="relative flex flex-col md:flex-row items-center justify-between px-4 py-12 md:px-8 md:py-20 bg-white bg-cover bg-center"
      style={{
        backgroundImage: `url(${wavesBackground})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      {/* Overlay to soften the background */}
      <div className="absolute inset-0 bg-white/60"></div>

      {/* Left side - Images */}
      <div className="relative z-10 flex justify-center md:justify-end md:w-1/2 space-x-4">
        <img src={PhoneImage1} alt="Phone 1" className="w-24 sm:w-36 md:w-48 lg:w-60 xl:w-72 object-contain" />
        <img src={PhoneImage2} alt="Phone 2" className="w-24 sm:w-36 md:w-48 lg:w-60 xl:w-72 object-contain" />
      </div>

      {/* Right side - Text */}
      <div className="relative z-10 md:w-1/2 mt-8 md:mt-0 md:pl-8 lg:pl-16 text-center md:text-left">
        <h2 className="text-2xl sm:text-3xl font-bold text-black mb-4">Our History</h2>
        <p className="text-gray-700 text-md sm:text-lg leading-relaxed tracking-wide">
          Roboti was founded in 2024 by a group of software developers with a passion for creating
          innovative solutions. Over the years, we have grown into a leading software development
          company, serving clients in a wide range of industries.
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
