// src/components/Testimonials.js
import React from 'react';
import Slider from 'react-slick';
import { FaStar } from 'react-icons/fa';
import image1 from '../assets/testimonial-images/testimonial1.jpg'; 
import image2 from '../assets/testimonial-images/testimonial2.jpg';
import image3 from '../assets/testimonial-images/testimonial3.jpg';
import image4 from '../assets/testimonial-images/testimonial4.jpg';
import image5 from '../assets/testimonial-images/testimonial5.jpg';
import image6 from '../assets/testimonial-images/testimonial6.jpg';

const testimonialsData = [
  {
    id: 1,
    image: image1,
    name: "Sarah Thompson",
    title: "Chief Technology Officer",
    rating: 5,
    text: "We were impressed by their expertise in both mobile and web app development. The team delivered top-notch solutions that seamlessly integrate with our existing systems.",
  },
  {
    id: 2,
    image: image2,
    name: "Madu Collins",
    title: "Project Manager",
    rating: 5,
    text: "Their mobile app development services transformed our business. The app is sleek, user-friendly, and exactly what we needed to engage our customers.",
  },
  {
    id: 3,
    image: image3,
    name: "Emily White",
    title: "Operations Director",
    rating: 5,
    text: "Their ability to develop a custom mobile app tailored to our needs has streamlined our processes significantly. The team was professional and proactive every step of the way.",
  },
  {
    id: 4,
    image: image4,
    name: "Daniel Green",
    title: "Marketing Manager",
    rating: 5,
    text: "The web application they developed for us has boosted our online presence. It’s responsive, fast, and beautifully designed. The development process was smooth and efficient.",
  },
  {
    id: 5,
    image: image5,
    name: "Emmanuella Anike",
    title: "UX/UI Designer",
    rating: 5,
    text: "Their attention to detail in creating both web and mobile apps is outstanding. They understood our vision perfectly and created apps that are both visually stunning and highly functional.",
  },
  {
    id: 6,
    image: image6,
    name: "Thomas Brown",
    title: "CEO",
    rating: 5,
    text: "Their team has a remarkable ability to understand our business goals and translate them into powerful mobile and web apps. The solutions they delivered have greatly improved our customer experience and operational efficiency.",
  },
];

function Testimonials() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // Laptops and above
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // Tablets
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="bg-blue-100 py-24">
      <h2 className="text-center text-2xl text-[#5A4DD4] mb-4 font-semibold">Testimonials</h2>
      <h3 className="text-center text-4xl font-bold mb-12">What our clients say</h3>
      <div className="max-w-4xl mx-auto px-4">
        <Slider {...settings}>
          {testimonialsData.map((testimonial) => (
            <div key={testimonial.id} className="bg-white rounded-lg p-10 sm:p-20 shadow-lg">
              <div className="flex justify-center mb-6">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-24 h-24 sm:w-32 sm:h-32 rounded-full border-4 border-white shadow-lg object-cover"
                />
              </div>
              <div className="text-center">
                <div className="flex justify-center mb-4">
                  {[...Array(testimonial.rating)].map((_, index) => (
                    <FaStar key={index} className="text-yellow-500 text-2xl" />
                  ))}
                </div>
                <p className="text-gray-700 mb-6 text-sm sm:text-lg">{testimonial.text}</p>
                <h4 className="font-bold text-xl sm:text-2xl text-[#5A4DD4]">{testimonial.name}</h4>
                <p className="text-md text-gray-500">{testimonial.title}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Testimonials;
