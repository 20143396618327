// src/components/Header.js
import React from 'react';
import { FaPhone, FaEnvelope, FaFacebookF, FaInstagram, FaLinkedinIn, FaYoutube } from 'react-icons/fa';
import Logo from '../assets/logo.png';

const Header = () => {
  return (
    <header className="shadow-md">
      {/* Top Contact Bar */}
      <div className="flex flex-col sm:flex-row justify-between items-center px-4 sm:px-6 py-3 bg-white text-gray-600 text-xs sm:text-sm border-b">
        <div className="flex flex-col sm:flex-row items-center space-y-2 sm:space-y-0 sm:space-x-3">
          <div className="flex items-center space-x-1">
            <FaPhone className="text-[#5A4DD4]" />
            <span>+964 770 783 8786,</span>
            <span>+234 80 6699 1590</span>
          </div>
          <div className="flex items-center space-x-1 ml-0 sm:ml-4">
            <FaEnvelope className="text-[#5A4DD4]" />
            <a href="mailto:info@hilance.app" className="hover:underline text-[#5A4DD4]">info@hilance.app</a>
          </div>
        </div>
        <div className="flex space-x-3 mt-2 sm:mt-0">
          <a href="https://web.facebook.com/profile.php?id=61567188335690" target="_blank" rel="noopener noreferrer">
            <FaFacebookF className="text-[#5A4DD4] cursor-pointer" />
          </a>
          <a href="https://www.instagram.com/hilance.app/" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="text-[#5A4DD4] cursor-pointer" />
          </a>
          <a href="https://www.linkedin.com/in/hilance-incorporation-36286a333/" target="_blank" rel="noopener noreferrer">
            <FaLinkedinIn className="text-[#5A4DD4] cursor-pointer" />
          </a>
          <a href="https://youtube.com/@hilanceinc?si=EadHVnV6Csv6dnos" target="_blank" rel="noopener noreferrer">
            <FaYoutube className="text-[#5A4DD4] cursor-pointer" />
          </a>
        </div>
      </div>

      {/* Main Navigation Bar */}
      <div className="flex justify-between items-center px-4 sm:px-8 py-2 bg-white">
        {/* Logo */}
        <img src={Logo} alt="Roboti Logo" className="w-44 sm:w-22" />

        {/* Navigation Links and Action Buttons */}
        <div className="flex items-center space-x-4 sm:space-x-6 text-xs sm:text-sm">
          {/* About Us Link */}
          <a href="#about" className="text-gray-600 hover:text-[#5A4DD4] cursor-pointer">
            About Us
          </a>

          {/* Contact Us Button */}
          <a href="#contact">
            <button className="px-3 py-1 border border-[#5A4DD4] rounded-full text-[#5A4DD4] hover:bg-[#E0E7FF] text-xs">
              Contact Us
            </button>
          </a>

          {/* Subscribe Button */}
          <a href="#subscribe"> {/* Change Subscribe button to an anchor link */}
            <button
              className="px-3 py-1 bg-[#5A4DD4] text-white rounded-full hover:bg-[#4C42B0] text-xs"
            >
              Subscribe
            </button>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
