// src/firebase.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyAWWci4J4lOoaiMQTcYqos-eLFnLDVFHOk",
    authDomain: "news-letter-ffbc4.firebaseapp.com",
    projectId: "news-letter-ffbc4",
    storageBucket: "news-letter-ffbc4.appspot.com",
    messagingSenderId: "499408476034",
    appId: "1:499408476034:web:ae8fed51ebad1b90f1219b",
    measurementId: "G-T6PHK0EM0T"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };
