import React from 'react';
import HeroVideo from '../assets/hero-bg-video.mp4'; // Ensure you have the video in the assets folder

const Hero = ({ titleSize = 'text-4xl', subtitleSize = 'text-lg' }) => {
  return (
    <section className="relative flex items-center text-white bg-cover bg-center py-20" style={{ height: '50vh' }}>
      {/* Video Background */}
      <video
        className="absolute inset-0 w-full h-full object-cover"
        src={HeroVideo}
        autoPlay
        loop
        muted
        playsInline
      ></video>

      {/* Overlay for better contrast */}
      <div className="absolute inset-0 bg-gradient-to-r from-black/80 via-black/60 to-transparent"></div>

      {/* Content */}
      <div className="relative z-20 max-w-lg px-30">
        <h1 className={`${titleSize} font-bold mb-4`}>Transform your digital landscape with Roboti</h1>
        <p className={`${subtitleSize} mb-8`}>A mobile app designed to simplify your everyday tasks.</p>
        <a href="https://apps.apple.com/ar/app/roboti/id6470292489?l=en-GB" target="_blank" rel="noopener noreferrer">
          <button className="bg-blue-500 hover:bg-blue-700 px-6 py-2 rounded-full text-lg font-semibold text-white">
            Download now
          </button>
        </a>
      </div>
    </section>
  );
};

export default Hero;
